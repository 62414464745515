<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat
        disable-sort
        class="mb-3 title"
        hide-default-footer
        loader-height="2"
        :loading="loading"
        :headers="headers"
        :items="suppliers.data"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-switch
            v-model="item.ussd_publication"
            @click.native="confirmBeforeUpdate(item)"
          />
          <app-dialog
            v-if="dialog"
            :dialog="dialog"
            @proceed="update()"
            @cancel="cancel()"
          />
        </template>
      </v-data-table>

      <app-pagination
        v-if="suppliers.meta"
        :meta="suppliers.meta"
        @pageChanged="pageChanged"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Client from '@/libs/iam/Client'

export default {
  data() {
    return {
      clientObj: new Client(),
      currentClient: null,
      dialog: false,
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Contact Person', value: 'contact_person.name' },
        { text: 'Date Registered', value: 'registered_at' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      suppliers: 'getClients',
    }),
  },

  methods: {
    ...mapActions(['setClients']),

    pageChanged(page) {
      this.page = page
      this.setClients()
    },

    loadSuppliers() {
      this.setClients({
        params: {
          circles: 'suppliers',
        },
      }).then(() => {
        this.loading = false
      })
    },

    update() {
      this.dialog = false
      this.loading = true
      this.clientObj.ussd_publication = this.currentClient.ussd_publication
      this.clientObj.update(this.currentClient.client_uid).then(() => {
        this.loading = false
      })
    },

    confirmBeforeUpdate(item) {
      this.currentClient = item
      this.dialog = true
    },

    cancel() {
      this.dialog = false
      this.loadSuppliers()
    },
  },

  mounted() {
    this.loadSuppliers()
  },
}
</script>
