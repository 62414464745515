import Base from '@/libs/core/Base'

const fields = {
  ussd_publication: '',
}

export default class Client extends Base {
  constructor() {
    super(fields)
  }

  update(clientUid) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit(
          'patch',
          `iam/clients/${clientUid}`,
          this.getFields()
        )
        console.log(clientUid)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}
